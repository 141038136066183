<template>
  <v-col class="px-0 py-0">
    <loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0">
      <v-row no-gutters align="center" style="margin: 20px 0px">
        <v-col class="py-0" style="padding-right: 10px">
          <charts-card
            title="Продано квитків"
            :numbers="dailySellTicketStatistic.total"
            :data="dailySellTicketStatistic.interval"
            type="Daily"
          />
        </v-col>
        <v-col class="py-0" style="padding: 0px 10px">
          <charts-card
            title="Загальна сума"
            :numbers="dailySellSumStatistic.total"
            :data="dailySellSumStatistic.interval"
            type="Daily"
          />
        </v-col>
        <!-- <v-col class="py-0" style="padding-left: 10px">
        <charts-card title="Сума знижок" numbers="1237" :procent="5" />
      </v-col> -->
      </v-row>
      <search-field
        label="Пошук"
        placeholder="Введіть тут.."
        @search="setSearch"
      />
      <div class="sectionBackground">
        <p class="sectionTitle">Фільтр</p>
        <div class="sectionLine" />
        <v-row no-gutters align="center">
          <v-col class="px-0 py-0">
            <p class="sectionSubtitle">Як це зробити?</p>
            <span class="sectionReportsText"
              >Налаштуйте дані в таблиці та завантажуйте</span
            >
          </v-col>
          <v-row no-gutters align="center" justify="end">
            <v-btn
              text
              style="
                font-size: 16px !important;
                color: #4b5262;
                text-transform: none !important;
                font-weight: 400;
                margin-right: 20px;
              "
              @click="resetFilters"
              >Скинути фільтри</v-btn
            >
            <submit-button
              @click="applyFilters"
              style="width: 180px !important; height: 48px !important"
              >Застосувати</submit-button
            >
          </v-row>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-top: 40px"
          v-if="$user_role !== 'dispatcher'"
        >
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Маршрут"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.routes"
              :items="Object.values(routesList)"
              :item-text="
                (item) =>
                  `${item.departure.translations?.[0].name} - ${item.destination.translations?.[0].name}`
              "
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="3" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Рейси"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              :disabled="filters.routes == ''"
              v-model="filters.trip"
              :items="Object.values(tripsList)"
              :item-text="
                (item) =>
                  `${new Date(item.departed_at).toLocaleDateString('uk-UA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })} - ${new Date(item.arrival_at).toLocaleDateString(
                    'uk-UA',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    }
                  )}`
              "
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="3" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Автобус"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.bus"
              :items="Object.values(busList)"
              :item-text="(item) => `${item.manufacturer} ${item.model}`"
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="3" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Статус квитка"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.status"
              :items="Object.values(statusTicket)"
              :item-text="'title'"
              :item-value="'value'"
            />
          </v-col>
          <v-col cols="3">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  height="48px"
                  label="Період продажів"
                  placeholder="Виберіть зі списку.."
                  style="border-radius: 10px"
                  background-color="#FFF"
                  color="#E2E2E2"
                  v-model="datesValue"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <div>
                <v-date-picker
                  range
                  color="#72afe7"
                  no-title
                  v-model="filters.dates"
                  :max="maxDate"
                  :min="minDate"
                ></v-date-picker>
              </div>
            </v-menu>
          </v-col>
          <v-col cols="3" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Знижки"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.discount"
              :items="Object.values(discountList)"
              :item-text="(item) => `${item?.translations?.[0]?.title}`"
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="3" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Промокоди"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.promocode"
              :items="Object.values(promocodeList)"
              :item-text="(item) => `${item?.translations?.[0]?.title}`"
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="3" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Бонусні"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.is_bonus"
              :items="Object.values(bonusStatus)"
              :item-text="'title'"
              :item-value="'value'"
            />
          </v-col>
        </v-row>
        <v-row no-gutters align="center" style="margin-top: 40px" v-else>
          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Маршрут"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="filters.routes"
              :items="Object.values(routesList)"
              :item-text="
                (item) =>
                  `${item.departure.translations?.[0].name} - ${item.destination.translations?.[0].name}`
              "
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="4" style="padding-left: 20px">
            <v-autocomplete
              outlined
              dense
              height="48px"
              label="Рейси"
              placeholder="Виберіть зі списку.."
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              :disabled="filters.routes == ''"
              v-model="filters.trip"
              :items="Object.values(tripsList)"
              :item-text="
                (item) =>
                  `${new Date(item.departed_at).toLocaleDateString('uk-UA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })} - ${new Date(item.arrival_at).toLocaleDateString(
                    'uk-UA',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    }
                  )}`
              "
              :item-value="'id'"
            />
          </v-col>
          <v-col cols="4" style="padding-left: 20px">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  height="48px"
                  label="Період продажів"
                  placeholder="Виберіть зі списку.."
                  style="border-radius: 10px"
                  background-color="#FFF"
                  color="#E2E2E2"
                  v-model="datesValue"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <div>
                <v-date-picker
                  range
                  color="#72afe7"
                  no-title
                  v-model="filters.dates"
                  :max="maxDate"
                  :min="minDate"
                ></v-date-picker>
              </div>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div class="sectionBackground">
        <p class="sectionTitle">Завантаження таблиці</p>
        <div class="sectionLine" />
        <v-row no-gutters align="center">
          <v-col class="px-0 py-0" cols="10">
            <p class="sectionSubtitle">Таблиця із застосованими фільтрами</p>
            <span class="sectionReportsText"
              >Натисніть на кнопку, щоб завантажити таблицю до якої застосовані
              фільтри</span
            >
          </v-col>
          <v-row no-gutters justify="end">
            <div
              class="iconBackground"
              style="width: 48px; height: 48px; padding: 8px"
            >
              <div class="downloadTicketIcon" />
            </div>
          </v-row>
        </v-row>
        <v-row no-gutters align="center" style="margin-top: 20px">
          <v-col class="px-0 py-0" cols="10">
            <p class="sectionSubtitle">Таблиця без застосування фільтрів</p>
            <span class="sectionReportsText"
              >Якщо потрібно завантажити всі дані в таблиці</span
            >
          </v-col>
          <v-row no-gutters justify="end">
            <div
              class="iconBackground"
              style="width: 48px; height: 48px; padding: 8px"
            >
              <div class="downloadTicketIcon" />
            </div>
          </v-row>
        </v-row>
      </div>
      <reports-list
        :reports="reports"
        :loading="loading"
        @sortBy="setSortByField"
      />
      <pagination
        :page="page"
        :quantityPage="quantityPage"
        @changePage="changePage"
      />
    </v-col>
  </v-col>
</template>

<script>
import discountService from "../../../requests/Admin/Discount/discountService";
import promocodeService from "../../../requests/Admin/Discount/promocodeService";
import statisticService from "../../../requests/Admin/statisticService";
import orderHistoryService from "../../../requests/Admin/orderHistoryService";
import routesService from "../../../requests/Admin/routesService";
import tripService from "../../../requests/Admin/tripService";
import busService from "../../../requests/Bus/busService";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import chartsCard from "../../UI/Cards/chartsCard.vue";
import SearchField from "../../UI/Fields/searchField.vue";
import Pagination from "../../UI/pagination.vue";
import ReportsList from "./reportsList.vue";
import debounce from "lodash/debounce";
import Loader from "../../UI/Loader.vue";
export default {
  components: {
    chartsCard,
    SubmitButton,
    SearchField,
    ReportsList,
    Pagination,
    Loader,
  },
  data: () => ({
    activeStatus: "all",
    reports: [],
    selectedDate: null,
    filters: {
      trip: null,
      bus: null,
      status: null,
      discount: null,
      promocode: null,
      is_bonus: "All",
      routes: "",
      dates: ["", ""],
    },
    routesList: [],
    busList: [],
    discountList: [],
    promocodeList: [],
    tripsList: [],
    statusTicket: [
      { id: 1, title: "Оплачено", value: "Payed" },
      { id: 1, title: "Заброньовано", value: "Booking" },
      { id: 1, title: "Оплата при посадці", value: "PayInBus" },
      { id: 2, title: "Скасовано", value: "Canceled" },
    ],
    bonusStatus: [
      { id: 1, title: "Застовано", value: "Yes" },
      { id: 2, title: "Не застосовано", value: "No" },
    ],
    sortBy: "",
    sortDesc: "",
    page: 1,
    quantityPage: 1,
    search: "",
    loading: true,
    showLoader: true,
    dailySellTicketStatistic: {},
    dailySellSumStatistic: {},
  }),
  mounted() {
    this.getStatistic();
    this.getReports();
    this.getRoutes();
    if (this.$user_role !== "dispatcher") {
      this.getBuses();
      this.getDiscounts();
      this.getPromocodes();
    }
  },
  methods: {
    async getStatistic() {
      const promises = [
        await statisticService
          .getMainStatistic("Daily", "TicketPayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.dailySellTicketStatistic = res.data?.[0];
            }
          }),
        await statisticService
          .getMainStatistic("Daily", "PricePayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.dailySellSumStatistic = res.data?.[0];
            }
          }),
      ];
      Promise.all(promises).then(() => {
        this.showLoader = false;
      });
    },
    changePage(page) {
      this.page = page;
      this.loading = true;
      this.getReports();
    },
    resetFilters() {
      this.filters = { routes: "", dates: ["", ""], is_bonus: "All" };
      this.loading = true;
      this.getReports();
    },
    setSearch(search) {
      this.search = search;
      this.getReports();
    },
    setSortByField(sortBy, sortDesc) {
      if (sortBy !== null && sortBy !== undefined && sortBy !== "") {
        this.sortBy = sortBy;
        this.sortDesc = sortDesc == true ? "DESC" : "ASC";
      }

      this.sortReports();
    },
    sortReports: debounce(function () {
      this.loading = true;
      this.getReports();
    }, 300),
    async getReports() {
      let search = this.setSearchFields();
      await orderHistoryService
        .getOrderHistory(
          this.page,
          search.length > 0
            ? JSON.stringify(search).replaceAll('"', "")
            : [`all[like]: ${this.search}`],
          this.sortBy,
          this.sortDesc,
          this.filters.is_bonus
        )
        .then((res) => {
          if (res.status == "Success") {
            this.reports = res.data;
            this.quantityPage = res.pagination.meta.last_page;
            this.loading = false;
          }
        });
    },
    async getRoutes() {
      await routesService.getRouteForAdmin().then((res) => {
        if (res.status == "Success") {
          this.routesList = res.data;
        }
      });
    },
    async getBuses() {
      await busService.getAllBuses().then((res) => {
        if (res.status == "Success") {
          this.busList = res.data;
        }
      });
    },
    async getDiscounts() {
      await discountService.getDiscounts().then((res) => {
        if (res.status == "Success") {
          this.discountList = res.data;
        }
      });
    },
    async getPromocodes() {
      await promocodeService.getPromocodes().then((res) => {
        if (res.status == "Success") {
          this.promocodeList = res.data;
        }
      });
    },
    async getTripsByRouteId() {
      await tripService.getTripsByRouteID(this.filters.routes).then((res) => {
        if (res.status == "Success") {
          this.tripsList = res.data;
        }
      });
    },
    applyFilters() {
      this.loading = true;
      this.getReports();
    },
    setSearchFields() {
      let search = [];
      if (
        this.filters.routes !== null &&
        this.filters.routes !== "" &&
        this.filters.routes !== undefined
      ) {
        search.push(`route_uuid[eq]:${this.filters.routes}`);
      }
      if (
        this.filters.trip !== null &&
        this.filters.trip !== "" &&
        this.filters.trip !== undefined
      ) {
        search.push(`schedule_uuid[eq]:${this.filters.trip}`);
      }
      if (
        this.filters.bus !== null &&
        this.filters.bus !== "" &&
        this.filters.bus !== undefined
      ) {
        search.push(`bus_id[eq]:${this.filters.bus}`);
      }
      if (
        this.filters.status !== null &&
        this.filters.status !== "" &&
        this.filters.status !== undefined
      ) {
        search.push(`status[eq]:${this.filters.status}`);
      }
      if (
        this.filters.promocode !== null &&
        this.filters.promocode !== "" &&
        this.filters.promocode !== undefined
      ) {
        search.push(`promocode_id[eq]:${this.filters.promocode}`);
      }
      if (
        this.filters.discount !== null &&
        this.filters.discount !== "" &&
        this.filters.discount !== undefined
      ) {
        search.push(`discount_id[eq]:${this.filters.discount}`);
      }
      return search;
    },
  },
  computed: {
    datesValue: {
      get() {
        if (this.filters.dates && this.filters.dates.length > 0) {
          return `${
            this.filters.dates[0] !== "" &&
            this.filters.dates[0] !== null &&
            this.filters.dates[0] !== undefined
              ? new Date(this.filters.dates[0]).toLocaleDateString("uk-UA", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : ""
          }-${
            this.filters.dates[1] !== "" &&
            this.filters.dates[1] !== null &&
            this.filters.dates[1] !== undefined
              ? new Date(this.filters.dates[1]).toLocaleDateString("uk-UA", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : ""
          }`;
        } else {
          return `${["", ""]}`;
        }
      },
      set(value) {
        this.filters.dates = value;
      },
    },
    maxDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate());
      return tomorrow
        .toLocaleString("en-CA", { timeZone: "UTC" })
        .split("T")[0];
    },
    minDate() {
      console.log(this.selectedDate);
      if (
        this.selectedDate !== null &&
        this.selectedDate !== "" &&
        this.selectedDate !== undefined
      ) {
        const tomorrow = new Date(this.selectedDate);
        tomorrow.setDate(tomorrow.getDate());
        console.log(tomorrow, "tomorrow");
        return tomorrow
          .toLocaleString("en-CA", { timeZone: "UTC" })
          .split("T")[0];
      }
      return null;
    },
  },
  watch: {
    "filters.dates": {
      deep: true,
      handler() {
        if (
          this.filters &&
          this.filters.dates &&
          this.filters.dates.length > 0
        ) {
          this.selectedDate = this.filters.dates[0];
        } else {
          this.selectedDate = null;
        }
      },
    },
    "filters.routes": {
      deep: true,
      handler() {
        if (this.filters.routes !== "") {
          this.getTripsByRouteId();
        }
      },
    },
  },
};
</script>

<style scoped>
.sectionSubtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #1b1b1b;
  margin-bottom: 4px !important;
}
.sectionReportsText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #4b5262;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>